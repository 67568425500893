import { createSlice } from "@reduxjs/toolkit";

export type AppState = {
  invocationIsValid: boolean;
};

const initialState: AppState = {
  invocationIsValid: false,
};

export const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInvocationIsValid: (state) => {
      state.invocationIsValid = true;
    },
  },
});

export const appReducer = app.reducer;
export const { setInvocationIsValid } = app.actions;
