import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectivityStatus } from "@prismadelabs/prismaid";

export type SwipeState = {
  connectivityStatus: ConnectivityStatus;
  progress: number;
  scaleFactor: number;
};

const initialState: SwipeState = {
  connectivityStatus: ConnectivityStatus.ok,
  progress: 0,
  scaleFactor: 0,
};

export const swipe = createSlice({
  name: "swipe",
  initialState,
  reducers: {
    setConnectivityStatus: (state, action: PayloadAction<ConnectivityStatus>) => {
      state.connectivityStatus = action.payload;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setScaleFactor: (state, action: PayloadAction<number>) => {
      console.warn("logging");
      state.scaleFactor = action.payload;
    },
  },
});

export const swipeReducer = swipe.reducer;
export const { setConnectivityStatus, setProgress, setScaleFactor } = swipe.actions;
