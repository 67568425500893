import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../state/store";

// types
const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

type ScaledImageSpecificProps = {
  src: string;
  id: string;
  alt: string;
  verticalAlign: string;
  horizontalAlign: string;
  verticalOffset: number;
  horizontalOffset: number;
  className?: string;
  opacity: number;
  scaleFactor: number;
};

type ScaledImageProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & ScaledImageSpecificProps;

type ScaledImageStates = {
  width: number;
};

// component
class ScaledImage extends React.Component<ScaledImageProps, ScaledImageStates> {
  horizontalPositioning: React.CSSProperties;
  verticalPositioning: React.CSSProperties;

  static defaultProps = {
    horizontalOffset: 0,
    verticalOffset: 0,
    opacity: 1,
  };

  constructor(props: ScaledImageProps) {
    super(props);

    this.state = {
      width: 0,
    };

    this.horizontalPositioning = {};
    this.verticalPositioning = {};
  }

  componentDidMount() {
    let img = new Image();
    img.src = this.props.src;
    img.onload = () => {
      this.setState({
        width: img.width,
      });
    };

    // horizontal
    switch (this.props.horizontalAlign) {
      case "left":
        this.horizontalPositioning = {
          left: 0 + this.props.horizontalOffset * (this.props.scaleFactor || 1),
        };
        break;
      case "center":
        this.horizontalPositioning = {
          left: `calc(50% + (${this.props.horizontalOffset * (this.props.scaleFactor || 1)}px))`,
          transform: "translateX(-50%)",
        };
        break;
      case "right":
        this.horizontalPositioning = {
          right: 0 - this.props.horizontalOffset * (this.props.scaleFactor || 1),
        };
        break;
      default:
        console.log("no horizontalAlign defined");
        break;
    }

    switch (this.props.verticalAlign) {
      case "top":
        this.verticalPositioning = {
          top: 0 + this.props.verticalOffset * (this.props.scaleFactor || 1),
        };
        break;
      case "center":
        this.verticalPositioning = {
          top: `calc(50% + (${this.props.verticalOffset * (this.props.scaleFactor || 1)}px))`,
          transform: `${this.props.horizontalAlign === "center" ? "translateX(-50%) translateY(-50%)" : "translateY(-50%)"}`,
        };
        break;
      case "bottom":
        this.verticalPositioning = {
          bottom: 0 - this.props.verticalOffset * (this.props.scaleFactor || 1),
        };
        break;
      default:
        console.log("no horizontalAlign defined");
        break;
    }
  }

  componentWillUnmount() {
    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    return (
      <img
        src={this.props.src}
        id={this.props.id}
        alt={this.props.alt}
        className={clsx("absolute transform select-none max-w-none touch-none pointer-events-none", this.props.className)}
        style={{
          width: this.state.width * (this.props.scaleFactor || 1),
          ...this.horizontalPositioning,
          ...this.verticalPositioning,
          opacity: this.props.opacity,
        }}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScaledImage);
