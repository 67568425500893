import Modal from "../../../../components/layout/Modal";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

const DeviceNotSupportedModal = (props: Props) => {
  return (
    <Modal title="Incompatible device" isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
      <p className="mt-4 whitespace-pre-wrap">
        Unfortunately, your device is not supported.
        <br />
        <br />
        Please open the link on another smartphone.
      </p>
    </Modal>
  );
};

export default DeviceNotSupportedModal;
