import clsx from "clsx";
import { useRef } from "react";
import Sound from "../../Sound";
import ChevronLeft from "../../../assets/img/00_general/back.png";
import click from "../../../assets/sounds/klick.mp3";

type Props = {
  onClick: any;
  className?: string;
};

const BackButton = (props: Props) => {
  const clickSound = useRef(new Sound(click));

  return (
    <button
      className={clsx("p-4", props.className)}
      onClick={() => {
        props.onClick();
        clickSound.current.play();
      }}
    >
      <img src={ChevronLeft} alt="chevron left" className="h-8" />
    </button>
  );
};

export default BackButton;
